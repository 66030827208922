'use client';
import { createContext, useContext, useMemo } from 'react';
import useSWR from 'swr';

import { fetchJson } from 'lib/fetch-json';
import toast from 'src/components/TailwindToaster';

type MyTestsType = {
    tests: any[];
    total: number;
    tax: number;
};

type MyTestsStateType = {
    loaded: boolean;
    addTest: (slug: string) => Promise<void>;
    removeTest: (slug: string) => Promise<void>;
    generateForm: (params: any) => Promise<any>;
    initializeTests: (tests: string[]) => void;
} & MyTestsType;

const MyTestsStateContext = createContext<MyTestsStateType>({
    tests: [],
    total: 0,
    tax: 0,
    loaded: false,
    addTest: () => Promise.resolve(),
    removeTest: () => Promise.resolve(),
    generateForm: () => Promise.resolve(),
    initializeTests: () => {},
});

export function MyTestsStateProvider(props: any) {
    const { data, mutate } = useSWR<MyTestsType>('/api/my-tests', fetchJson, {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
    });

    const value = useMemo<MyTestsStateType>(() => {
        return {
            tests: data?.tests || [],
            total: data?.total || 0,
            tax: data?.tax || 0,
            loaded: !!data,
            addTest: async (slug: string) => {
                await fetchJson(`/api/my-tests`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ slug }),
                }).catch(() => {
                    toast.error('Eroare la adăugarea analizei');
                });

                await mutate();

                toast.success(
                    <>
                        Analiza a fost adăugată.
                        <div className="text-xs">Apasă pe calendarul de mai sus pentru a finaliza programarea</div>
                    </>,
                    {
                        id: 'test-added',
                        duration: 7500,
                    },
                );
            },
            removeTest: async (slug: string) => {
                await fetchJson(`/api/my-tests/${slug}`, {
                    method: 'DELETE',
                    headers: { 'Content-Type': 'application/json' },
                });

                await mutate();
            },
            initializeTests: async (tests: string[]) => {
                await fetchJson(`/api/my-tests/initialize`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ tests }),
                });

                await mutate();
            },
            generateForm: async (params: any) => {
                return fetchJson(`/api/forms/analizele-mele`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(params),
                });
            },
        };
    }, [data, mutate]);

    return <MyTestsStateContext.Provider value={value} {...props} />;
}

// a hook which we are going to use whenever we need data from `MyTestsStateProvider`
export default function useMyTestsState() {
    const context = useContext(MyTestsStateContext);

    if (!context) {
        throw new Error('You need to wrap MyTestsStateProvider.');
    }

    return context;
}
