'use client';
import { ReactNode } from 'react';

import BlogPostCard from './BlogPostCard';
import ScrollWithCarets from './ScrollWithCarets';

type Props = {
    blogPosts: any[];
    moreCard?: ReactNode;
};

export default function ScrollableBlogPosts({ blogPosts, moreCard }: Props) {
    const liClassName = 'w-80 flex-none bg-white shadow-md snap-center';

    return (
        <ScrollWithCarets caretsClassName="top-1/4">
            <div className="container relative mx-auto h-[400px] overflow-visible md:h-[560px]">
                <ul className="md:pr-margin-semi-container absolute flex flex-nowrap gap-4 p-4">
                    {blogPosts.map((item) => (
                        <li data-carousel-item key={item.slug} className={liClassName}>
                            <BlogPostCard post={item} imageSizes="320px" />
                        </li>
                    ))}
                    {moreCard && (
                        <li data-carousel-item className={liClassName}>
                            {moreCard}
                        </li>
                    )}
                </ul>
            </div>
        </ScrollWithCarets>
    );
}
