export async function fetchJson<JSON = unknown>(input: RequestInfo, init?: RequestInit): Promise<JSON> {
    const response = await fetch(input, init);

    // if the server replies, there's always some data in json
    // if there's a network error, it will throw at the previous line
    const data = await response.json();

    // response.ok is true when res.status is 2xx
    // https://developer.mozilla.org/en-US/docs/Web/API/Response/ok
    if (response.ok) {
        return data;
    }

    throw new FetchError({
        message: response.statusText,
        response,
        data,
    });
}

export async function fetchJsonById<JSON = any>([url, id]: [url: string, id: string]): Promise<any> {
    if (!id) {
        return undefined;
    }
    const res: any = await fetchJson(`${url}/${id}`);
    return res.data;
}

export async function fetchJsonWithQuery<JSON = any>([url, params]: [url: string, params?: any]): Promise<any> {
    const _params = { ...(params || {}) };
    Object.keys(_params).forEach((key) => {
        if (_params[key] === undefined) {
            delete _params[key];
        }
    });

    const searchParams = new URLSearchParams(_params);
    searchParams.sort();
    const str = searchParams.toString();

    const res: any = await fetchJson(str ? `${url}?${searchParams}` : url);
    return res?.data;
}

export class FetchError extends Error {
    response: Response;
    data: {
        message: string;
        error: any;
    };
    constructor({
        message,
        response,
        data,
    }: {
        message: string;
        response: Response;
        data: {
            message: string;
            error: any;
        };
    }) {
        // Pass remaining arguments (including vendor specific ones) to parent constructor
        super(message);

        // Maintains proper stack trace for where our error was thrown (only available on V8)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, FetchError);
        }

        this.name = 'FetchError';
        this.response = response;
        this.data = data ?? { message: message, error: 'FetchError' };
    }
}

export function getFormData(params: any) {
    return Object.keys(params).reduce((formData, key) => {
        formData.append(key, params[key]);
        return formData;
    }, new FormData());
}
