import { DetailedHTMLProps, HTMLAttributes } from 'react';
import Link from 'next/link';
import Image from 'next/image';

import { blogPostUrl } from 'src/services/route-utils';

type Props = {
    post: any;
    imageSizes?: string;
    contentClassName?: string;
    titleHeadingLevel?: number;
};

function Heading({
    headingLevel,
    ...rest
}: {
    headingLevel: number;
} & DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>) {
    const H = `h${headingLevel}`;
    return <H {...rest} />;
}

export default function BlogPostCard({ post, imageSizes, contentClassName, titleHeadingLevel = 3 }: Props) {
    return (
        <div className="group">
            <div className="bg-light-green relative h-44 md:h-80">
                <Link className="block h-full" href={blogPostUrl(post)}>
                    {post.cover_image?.thumbnail && (
                        <Image
                            className="object-cover"
                            alt={post.name}
                            src={post.cover_image.thumbnail}
                            placeholder={post.cover_image.placeholder}
                            sizes={imageSizes || undefined}
                            fill
                        />
                    )}
                </Link>
            </div>
            <div className={contentClassName || 'p-4'}>
                <Link href={blogPostUrl(post)}>
                    <Heading
                        headingLevel={titleHeadingLevel}
                        className="mb-4 line-clamp-2 text-lg decoration-1 underline-offset-4 group-hover:underline">
                        {post.name}
                    </Heading>
                </Link>
                <div className="line-clamp-3 text-sm" dangerouslySetInnerHTML={{ __html: post.content_raw }} />
            </div>
        </div>
    );
}
